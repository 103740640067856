import { ReactChild } from 'react';

type TProps = {
    children: JSX.Element | JSX.Element[] | null;
};

const SideModalFooter = ({ children }: TProps) => {
    return (
        <div className="fixed bottom-0 right-0 w-full bg-white md:max-w-[710px] border-t-[1px] bg-gray">
            <div className="flex md:justify-end justify-between py-5 mx-7.5">
                {children}
            </div>
        </div>
    );
};

export default SideModalFooter;
