import React from 'react';
import clsx from 'clsx';

type TProp = {
    className?: string;
};

export const BasicSpinner = (props: TProp) => (
    <div
        className={clsx(
            'border-b-white rounded-full animate-spin',
            props.className
                ? props.className
                : 'w-12.5 h-12.5 border-sky-800 border-[5px]',
        )}
    />
);

export const GeneralSpinner = (props?: TProp) => (
    <BasicSpinner
        className={clsx(
            'h-[90px] w-[90px] border-yellow-800 border-[3px]',
            props?.className,
        )}
    />
);

export const BigGeneralSpinner = (props?: TProp) => (
    <BasicSpinner
        className={clsx(
            'h-[17.5rem] w-[17.5rem] border-yellow-800 border-[10px]',
            props?.className,
        )}
    />
);

export const Loader = (props?: TProp) => (
    <BasicSpinner
        className={clsx(
            'h-48 w-48 border-blue border-[20px] border-b-[rgba(0,0,0,0)]',
            props?.className,
        )}
    />
);
