import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactElement } from 'react';
import XIcon from '@heroicons/react/outline/XIcon';
import React from 'react';

type SideDialogWrapper = {
    onClick: (value: boolean) => any;
    value: boolean;
    children: ReactElement | ReactElement[] | JSX.Element | JSX.Element[];
};

export const SideDialogWrapper = ({
    children,
    onClick,
    value,
}: SideDialogWrapper) => (
    <div>
        <Transition.Root show={value} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 flex z-40"
                onClose={onClick}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative flex-1 flex flex-col max-w-xs w-full pt-3 pb-4 bg-blue">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute top-0 right-0 mr-4 mt-5 pt-2">
                                <button
                                    type="button"
                                    className="ml-1 flex items-center justify-center border-none focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white"
                                    onClick={() => onClick(!value)}
                                >
                                    <span className="sr-only">
                                        Close sidebar
                                    </span>
                                    <XIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </Transition.Child>
                        {React.isValidElement(children) &&
                            React.cloneElement(
                                children as React.ReactElement<any>,
                                {
                                    closeDialogMenu: () => onClick(!value),
                                },
                            )}
                    </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
            </Dialog>
        </Transition.Root>
    </div>
);

export default SideDialogWrapper;
