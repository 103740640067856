import { Fragment, useContext, useState } from 'react';
import Link from 'next/link';
import { classNames } from '../../../libs/utils';
import { userNavigation } from 'src/constants/navigation';
import { Menu, Transition } from '@headlessui/react';
import SignOut from '../../auth/SignOut';
import PhotoPlaceholder from 'assets/images/photoPlaceholder.svg';
import { AccountContext } from 'src/contexts/accountContext';
import {
    NotificationModal,
    NotificationStatus,
} from 'src/components/general/NotificationModal';
import {
    useFetchNotificationCount,
    useFetchNotifications,
    useMarkAllNotificationsAsClicked,
} from 'src/services/useNotifications';
import { BarMenuBellButton } from './BellButton';
import { useSwitch } from 'src/hooks/useSwitch';
import { markNotificationAsClicked } from 'src/libs/http-api';

export const BarMenu = () => {
    const { accountData } = useContext(AccountContext);
    const [count] = useFetchNotificationCount();
    const [notificationIsOpen, toggleNotificationIsOpen] = useSwitch();
    const markNotificationsAsClicked = useMarkAllNotificationsAsClicked();
    const [notifications, loadNotifications] = useFetchNotifications();
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const onOpenNotification = () => {
        setTimeout(() => {
            markNotificationsAsClicked();
        }, 3000);

        toggleNotificationIsOpen();
        loadNotifications();
    };
    return (
        <div className="ml-4 flex items-center md:ml-6 bg-blue sm:bg-blue lg:bg-white md:bg-white">
            <BarMenuBellButton count={count} onClick={onOpenNotification} />
            <NotificationModal
                onClose={onOpenNotification}
                open={notificationIsOpen}
                notifications={notifications}
            />
            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative">
                <div>
                    <Menu.Button
                        onClick={() => setMenuIsOpen(true)}
                        className="max-w-xs bg-blue sm:bg-blue lg:bg-white md:bg-white flex items-center text-sm rounded-full focus:outline-none onfocus-ring-blue"
                    >
                        <span className="sr-only">Open user menu</span>

                        {!!accountData?.avatar ? (
                            <img
                                className="w-15 h-15 rounded-full"
                                style={{ transform: 'scale(0.54)' }}
                                src={accountData?.avatar}
                            />
                        ) : (
                            <PhotoPlaceholder
                                style={{ transform: 'scale(0.54)' }}
                                className=" rounded-full"
                            />
                        )}
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    show={menuIsOpen}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                                {({ active }) => (
                                    <Link href={item.href}>
                                        <a
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700',
                                            )}
                                            onClick={() => setMenuIsOpen(false)}
                                        >
                                            {item.name}
                                        </a>
                                    </Link>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>

            <SignOut />
        </div>
    );
};
