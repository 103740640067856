import BellIcon from '@heroicons/react/outline/BellIcon';

const BellNotificationCounter = ({ count }: { count: number }) => {
    if (!count) return <></>;
    return (
        <div className="text-xs min-w-6 absolute -top-1 -right-1 w-fits flex items-center justify-center w-6 h-6 rounded-full bg-red-500 text-white">
            {count}
        </div>
    );
};

export const BarMenuBellButton = ({
    onClick,
    count,
}: {
    onClick: () => any;
    count: number;
}) => {
    return (
        <button
            onClick={onClick}
            type="button"
            className="relative bg-blue sm:bg-blue lg:bg-white md:bg-white p-1 rounded-full text-white lg:text-black md:text-black hover:text-gray-500 focus:outline-none onfocus-ring-blue"
        >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6 " aria-hidden="true" />
            <BellNotificationCounter count={count}></BellNotificationCounter>
        </button>
    );
};
